import { message } from "antd";
import { config } from "../configs";
import { localStrings } from "./constants";
import DM from './DataManagement';
import { sortByTurkishCharacters } from './helperFunctions'

const configURL = config.server_url;
const { fastSearchType } = localStrings;

// https://medium.com/javascript-in-plain-english/async-await-javascript-5038668ec6eb


export async function requestToServer(endPoint, requestOptions) {
    let url = configURL + endPoint;
    // console.log("request sending:url:", url)
    // console.log("request sending:requestOptions.body:", requestOptions && requestOptions.body && JSON.parse(requestOptions.body))
    return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((obj) => {
            // console.log("response.obj:", obj)
            if (!obj.success && (obj.status == 401 || obj.status == 403)) {
                // console.log("dbFunctions:requestToServer:hataya girdi");
                message.error(obj.message);
                localStorage.clear();
                return false;
            } else {
                if (obj.success) {
                    return obj;
                } else {
                    message.error(obj.message);
                    return { success: false };
                }
            }
        })
        .catch(err => console.log(err));
}

export async function checkToken(type) {
    let endPoint = "/admin/check-token?type=" + type;
    let requestOptions = {
        method: "GET",
        headers: { 'Authorization': 'Bearer: ' + localStorage.getItem('access_token') },
    };

    const response = await requestToServer(endPoint, requestOptions);

    // console.log('Token Check response:', response)
    return response;
}

export async function fetchCategories() {
    let url = configURL + "/categories";

    if (DM.getCategories()) {
        return DM.getCategories();
    }

    return fetch(url)
        .then((response) => response.json())
        .then((obj) => {
            console.log('Categories are fetched'); //, obj.data)
            DM.setCategories(obj.data);
            return obj.data;
        });
}

export async function fetchBrands() {
    let url = configURL + "/brands";

    if (DM.getBrands())
        return DM.getBrands();

    return fetch(url)
        .then((response) => response.json())
        .then((obj) => {
            console.log('Brands are fetched'); //, obj.data)
            DM.setBrands(obj.data);
            return obj.data;
        });
}

export async function fetchBrand(id) {
    let existBrand = DM.findBrand(id);
    if (existBrand)
        return existBrand;

    let url = configURL + "/brands/" + id;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer: ' + localStorage.getItem('access_token')
        },
        credentials: 'same-origin',
        withCredentials: true
    };

    return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((obj) => {
            console.log('Brand is fetched'); //, obj.data)
            DM.updateBrands(obj.data);
            return obj.data;
        });
}

/**
 * If there exists a query string then request it from db, otherwise return from the Data Management's product pool
 * @param {String} queryStr sample query string is like that: category=L&brand_id=BR-000002&collection_name=MEGA&
 */
export async function fetchProducts(queries) {
    // let url = configURL + '/products/withfilter?' + queryStr; // "/products/withfilter?brand_id=BR-000002";
    let url = configURL + '/products/withfilter'
    
    if (!queries)
        if (DM.getProducts()) {
            // console.log('from.DM:', DM.getProducts())
            return DM.getProducts();
        }

    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({ queries }),
        // mode: 'no-cors',
        credentials: 'same-origin',
        withCredentials: true
    };

    return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((obj) => {
            DM.setProducts(obj.data);
            console.log('Products are fetched'); //, obj)
            return obj.data;
        });
}

export async function fetchProduct(id) {
    let url = configURL + "/products/id/" + id; // previously /products/materia_id

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        credentials: 'same-origin',
        withCredentials: true
    };

    return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((obj) => {
            // DM.setProduct(obj.data);
            console.log('Product: ' + obj.data.materia_id + ' is fetched'); //, obj.data)
            return obj.data;
        });
}

/**
 * It asynchronously fetches all the information related to given dealer id
 * @param {Object<Dealer>} dealer Dealer object as a parameter
 * @param {number} num
 * @returns {Promise<Dealer>}
 */
export async function fetchDealers() {
    let url = configURL + "/dealers";

    if (DM.getDealers())
        return DM.getDealers();

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer: ' + localStorage.getItem('access_token')
        },
        credentials: 'same-origin',
        withCredentials: true
    };

    return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((obj) => {
            DM.setDealers(obj.data);
            console.log('Dealers are fetched'); //, obj.data)
            return obj.data;
        });
}

//! will be deleted later, because we have advanced filter function
export const fetchProductsWithCategory = async (category_id) => {
    let url = configURL + "/categories/" + category_id + "/products";

    return fetch(url)
        .then((response) => response.json())
        .then((obj) => {
            DM.setProducts(obj.data);
            return obj.data;
        })
}

export async function deleteProduct(id) {

    let endPoint = "/products/id/" + id + "/delete";

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer: ' + localStorage.getItem('access_token')
        }
    };
    const response = await requestToServer(endPoint, requestOptions);

    // console.log('dbFunctions::Delete product response:', response)
    return response;
}

export async function deleteBrand(id) {

    let endPoint = "/brands/" + id + "/delete";

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer: ' + localStorage.getItem('access_token')
        }
    };

    const response = await requestToServer(endPoint, requestOptions);

    // console.log('dbFunctions::Delete brand response:', response)
    return response;

}

export async function deleteDealer(id) {

    let endPoint = "/dealers/" + id + "/delete";

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer: ' + localStorage.getItem('access_token')
        }
    };

    const response = await requestToServer(endPoint, requestOptions);

    // console.log('dbFunctions::Delete dealer response:', response)
    return response;

}

export async function fetchUsers() {
    if (DM.getUsers())
        return await DM.getUsers();

    let endPoint = "/users/all?userRole=user";
    let requestOptions = {
        method: "GET",
        headers: { 'Authorization': 'Bearer: ' + localStorage.getItem('access_token') },
    };

    const response = await requestToServer(endPoint, requestOptions);

    // console.log('dbFunctions::Fetch users response:', response)
    DM.setUsers(response.data);
    return response.data;
}

export async function fetchPersonels() {
    if (DM.getPersonels())
        return await DM.getPersonels();

    let endPoint = "/users/all?userRole=personel";
    let requestOptions = {
        method: "GET",
        headers: { 'Authorization': 'Bearer: ' + localStorage.getItem('access_token') },
    };

    const response = await requestToServer(endPoint, requestOptions);

    // console.log('dbFunctions::Fetch personels response:', response)
    DM.setPersonels(response.data);
    return response.data;
}

export async function deleteUser(username) {
    let endPoint = "/users/delete/" + username;

    const requestOptions = {
        method: "DELETE",
        headers: {
            "Authorization": "Bearer: " + localStorage.getItem("access_token")
        }
    };

    const response = await requestToServer(endPoint, requestOptions);
    return response;
}

export async function deleteCategory(id) {
    let endPoint = "/categories/" + id + "/delete";

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer: ' + localStorage.getItem('access_token')
        },
        credentials: 'same-origin',
        withCredentials: true
    };

    const response = await requestToServer(endPoint, requestOptions);
    return response;
}

export async function fetchBlogs() {
    if (DM.getBlogs())
        return DM.getBlogs();

    let endPoint = "/blog/blogs";

    const response = await requestToServer(endPoint);
    DM.setBlogs(response.data);
    return response.data;

}

export async function fetchSlider() {
    if (DM.getSlider())
        return DM.getSlider();

    let endPoint = "/blog/slider";

    const response = await requestToServer(endPoint);
    DM.setSlider(response.data);
    return response.data;
}


export async function fetchBrandsCollectionNames(brand_id) {
    let endPoint = "/brands/" + brand_id + "/collection_names";

    const response = await requestToServer(endPoint);
    // console.log("fetchBrandsCollectionNames.response:", response);
    if (response && response.count > 0) {
        return response.data.sort((a, b) => sortByTurkishCharacters(a, b)).map((name) => {
            return { value: name };
        });
    } else {
        return []
    }
}

export async function fetchBrandsModels(brand_id, collection_name) {
    let endPoint = "/brands/" + brand_id + "/" + collection_name + "/models";
    // console.log("fetchBrandsModels.endPoint:", endPoint);
    const response = await requestToServer(endPoint);
    // console.log("fetchBrandsModels.response:", response);
    if (response && response.count > 0) {
        return response.data.sort((a, b) => sortByTurkishCharacters(a, b)).map((name) => {
            return { value: name };
        });
    } else {
        return []
    }
}

export async function getProductsForAudit(obj) {
    let endPoint = "/admin/product-audit";
    // console.log("getProductsForAudit.endPoint:", endPoint);
    let requestOptions = {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer: ' + localStorage.getItem('access_token')
        },
        body: JSON.stringify(obj)
    };
    const response = await requestToServer(endPoint, requestOptions);
    // console.log("getProductsForAudit.response:", response);
    return response;
}

export async function fetchProfessionals() {
    if (DM.getProfessionals()) {
        return await DM.getProfessionals();
    }

    let endPoint = "/admin/get-all-professionals";
    let requestOptions = {
        method: "GET",
        headers: { 'Authorization': 'Bearer: ' + localStorage.getItem('access_token') },
    };

    const response = await requestToServer(endPoint, requestOptions);
    DM.setProfessionals(response.data)
    return response.data;
}


export async function fetchServices() {
    if (DM.getServices()) {
        return DM.getServices();
    }

    let endPoint = "/services";
    let requestOptions = {
        method: "GET",
        headers: { 'Authorization': 'Bearer: ' + localStorage.getItem('access_token') },
    };

    const response = await requestToServer(endPoint, requestOptions);
    DM.setServices(response.data)
    return response.data;
}

export async function deleteService(id) {
    let endPoint = "/services/" + id + "/delete"

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer: ' + localStorage.getItem('access_token')
        },
        credentials: 'same-origin',
        withCredentials: true
    };

    const response = await requestToServer(endPoint, requestOptions);
    return response;
}

export async function fetchFastSearches(type) {
    let endPoint = "/fast-search?type=";
    let requestOptions = {
        method: "GET",
        headers: { 'Authorization': 'Bearer: ' + localStorage.getItem('access_token') },
    };
    let response;

    if (type === fastSearchType.service) {
        if (DM.getServiceFastSearches()) {
            return DM.getServiceFastSearches();
        }
        endPoint += fastSearchType.service;
        // console.log('endpoint.service', endPoint)
        response = await requestToServer(endPoint, requestOptions);
        // console.log('endpoint.response',response)
        DM.setServiceFastSearches(response.data.data)
        return response.data.data;
    }
    if (type === fastSearchType.product) {
        if (DM.getProductFastSearches()) {
            return DM.getProductFastSearches();
        }
        endPoint += fastSearchType.product;
        // console.log('endpoint.product', endPoint)
        response = await requestToServer(endPoint, requestOptions);
        // console.log('endpoint.response',response)
        DM.setProductFastSearches(response.data.data)
        return response.data.data;
    }
}

export async function fetchHomepageSegments() {
    let url = configURL + "/homepage-segments";

    if (DM.getHomepageSegments()) {
        return DM.getHomepageSegments();
    }

    return fetch(url)
        .then((response) => response.json())
        .then((obj) => {
            console.log('Homepage segments are fetched');
            DM.setHomepageSegments(obj.data);
            return obj.data;
        })
        .catch((error) => {
            console.error('Error fetching homepage segments:', error);
            throw error;
        });
}

export async function fetchHomepageSegment(type) {
    let existSegment = DM.getHomepageSegment(type);
    if (existSegment) {
        return existSegment;
    }

    let url = `${configURL}/segments/${type}`;

    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer: ' + localStorage.getItem('access_token')
        },
        credentials: 'same-origin',
        withCredentials: true
    };

    return fetch(url, requestOptions)
        .then((response) => response.json())
        .then((obj) => {
            console.log('Homepage segment is fetched');
            DM.setHomepageSegment(obj.data);
            return obj.data;
        })
        .catch((error) => {
            console.error('Error fetching homepage segment:', error);
            throw error;
        });
}
