import { EmptyLayout, MainLayout } from "components/Layout";
import PageSpinner from "components/PageSpinner";
import AuthPage from "pages/AuthPage";
import React from "react";
import componentQueries from "react-component-queries";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from './components/PrivateRoute';
import "styles/reduction.scss";
import 'utils/utilityFunctions'

/* Home Page */
const Homepage = React.lazy(() => import("pages/Homepage"))

/* User Pages */
const Users = React.lazy(() => import("pages/admin/user-manage/Users"));
const Personels = React.lazy(() => import("pages/admin/user-manage/Personels"));

/* Product Pages */
const Products = React.lazy(() => import("pages/brand-catalog/product-manage/Products"));
const ProductAdd = React.lazy(() => import("pages/brand-catalog/product-manage/ProductAdd"));
const ProductEdit = React.lazy(() => import("pages/brand-catalog/product-manage/ProductEdit"));
const ProductAudit = React.lazy(() => import("pages/brand-catalog/product-manage/ProductAudit"));

/* Brand Pages */
const BrandManagement = React.lazy(() => import("pages/brand-catalog/brand-dealer/brand-manage/BrandManagement"));
const BrandAdd = React.lazy(() => import("pages/brand-catalog/brand-dealer/brand-manage/BrandAdd"));
const BrandEdit = React.lazy(() => import("pages/brand-catalog/brand-dealer/brand-manage/BrandEdit"));

/* Dealer Pages */
const DealerManagement = React.lazy(() => import("pages/brand-catalog/brand-dealer/dealer-manage/DealerManagement"));
const DealerEdit = React.lazy(() => import("pages/brand-catalog/brand-dealer/dealer-manage/DealerEdit"));
const DealerAdd = React.lazy(() => import("pages/brand-catalog/brand-dealer/dealer-manage/DealerAdd"));

/* Category Pages */
const CategoryManagementPage = React.lazy(() => import("pages/brand-catalog/product-category-manage/CategoryManagement"));
const CategoryGalleriesPage = React.lazy(() => import("pages/brand-catalog/product-category-manage/CategoryGalleries"));

/* Blog - Slider Pages */
const BlogManagement = React.lazy(() => import("pages/admin/blog-manage/BlogManagement"));
const BlogSliderManagement = React.lazy(() => import("pages/admin/blog-manage/BlogSliderManagement"));

/* Feedback Pages */
// const FeedbackManagement = React.lazy(() => import("pages/FeedbackManagement"));

/* Website Pages */
const Professionals = React.lazy(() => import("pages/admin/user-manage/professionals/ProfessionalsPage"));
// const ProfessionalDetail = React.lazy(() => import("pages/admin/user-manage/professionals/ProfessionalDetailPage"));
// const ProfessionalEdit = React.lazy(() => import("pages/admin/user-manage/professionals/ProfessionalEditPage"));
// const ProjectDetail = React.lazy(() => import("pages/admin/user-manage/professionals/ProjectDetailPage"));
// const IdeaPanoDetailPage = React.lazy(() => import("components/Professional/IdeaPanoDetails"));
// const ServiceManagement = React.lazy(() => import("pages/prof-services/service-category-manage/ServiceManagement"));

/* YENI EKLENENLER */
const Ads = React.lazy(() => import("pages/admin/Ads"))
const SssFooter = React.lazy(() => import("pages/admin/SssFooter"))
const Shortcut = React.lazy(() => import("pages/explore/Shortcut"))
const HomepageEdits = React.lazy(() => import("pages/homepage/HomepageEdits"))
const FeaturedServices = React.lazy(() => import("pages/prof-services/FeaturedServices"))
const ServiceFastSearch = React.lazy(() => import("pages/prof-services/service-category-manage/ServiceFastSearch"))
const ProductFastSearch = React.lazy(() => import("pages/brand-catalog/product-category-manage/ProductFastSearch"))
const ServiceCategoryManage = React.lazy(() => import("pages/prof-services/service-category-manage/ServiceCategoryManage"))

class App extends React.Component {

  render() {
    const isLoggedIn = () => {
      let exist;
      if (localStorage.getItem('access_token') !== null) {
        exist = true
      } else {
        exist = false
      }
      console.log('isLoggedIn: ' + exist);
      return exist;
    }


    return (
      <BrowserRouter>
        <Switch>
          <PrivateRoute component={() => isLoggedIn() ? <Redirect to="/" /> : <AuthPage />} layout={EmptyLayout} path="/login" exact />
          <MainLayout breakpoint={this.props.breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>

              <PrivateRoute component={Homepage} layout={EmptyLayout} path="/" exact onEnter={isLoggedIn} />

              <Route exact path="/users" component={Users} />
              <Route exact path="/personels" component={Personels} />

              <Route exact path="/products" component={Products} />
              <Route exact path="/product-add" component={ProductAdd} />
              <Route exact path="/productEdit" component={ProductEdit} />
              <Route exact path="/productAudit" component={ProductAudit} />

              <Route exact path="/brandManagement" component={BrandManagement} />
              <Route exact path="/brandAdd" component={BrandAdd} />
              <Route exact path="/brandEdit" component={BrandEdit} />

              <Route exact path="/dealerManagement" component={DealerManagement} />
              <Route exact path="/dealerAdd" component={DealerAdd} />
              <Route exact path="/dealerEdit" component={DealerEdit} />

              <Route exact path="/categoryManagement" component={CategoryManagementPage} />
              <Route exact path="/categoryGalleries" component={CategoryGalleriesPage} />

              <Route exact path="/blogManagement" component={BlogManagement} />
              <Route exact path="/blogSliderManagement" component={BlogSliderManagement} />

              {/* <Route exact path="/feedbackManagement" component={FeedbackManagement} /> */}

              <Route exact path="/professionals" component={Professionals} />
              {/* <Route exact path="/professional-detail" component={ProfessionalDetail} />
                <Route exact path="/professional-edit" component={ProfessionalEdit} />
                <Route exact path="/project-detail" component={ProjectDetail} />
                <Route exact path="/idea-pano-detail" component={IdeaPanoDetailPage} /> */}

              <Route exact path="/homepage/homepage-edits" component={HomepageEdits} />
              <Route exact path="/admin/ads" component={Ads} />
              <Route exact path="/admin/sss-footer" component={SssFooter} />
              <Route exact path="/explore/shortcut" component={Shortcut} />
              <Route exact path="/prof-services/featured" component={FeaturedServices} />
              <Route exact path="/prof-services/service-category-management" component={ServiceCategoryManage} />
              <Route exact path="/prof-services/fast-search" component={ServiceFastSearch} />
              <Route exact path="/brand-catalog/fast-search" component={ProductFastSearch} />


            </React.Suspense>
          </MainLayout>
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: "xs" };
  }

  if (576 < width && width < 1000) {
    return { breakpoint: "sm" };
  }

  if (1000 < width && width < 1500) {
    return { breakpoint: "md" };
  }

  if (1500 < width && width < 2000) {
    return { breakpoint: "lg" };
  }

  if (width > 1200) {
    return { breakpoint: "xl" };
  }

  return { breakpoint: "xs" };
};

export default componentQueries(query)(App);