
export const sortByTurkishCharacters = (a, b) => {
    const alfabe = "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
    if (a.length === 0 || b.length === 0) {
        return a.length - b.length;
    }
    for (let i = 0; i < a.length && i < b.length; i++) {
        const ai = alfabe.indexOf(a[i]);
        const bi = alfabe.indexOf(b[i]);
        if (ai !== bi) {
            return ai - bi;
        }
    }
}