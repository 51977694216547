export const allPaths = [
    { path: '/', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }] },
    { path: '/categoryManagement', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Marka Kataloğu', active: true }, { name: "Kategori Yönetimi", active: true }] },
    { path: '/categoryGalleries', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Marka Kataloğu', active: true }, { name: "Arama Kategorileri", active: true }] },
    { path: '/collectionManagement', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: "Koleksiyon Yönetimi", active: true }] },
    { path: '/users', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: "Admin", active: true }, { name: "Kullanıcı Yönetimi", active: true }, { name: "Kullanıcılar", active: true }] },
    { path: '/personels', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: "Admin", active: true }, { name: "Kullanıcı Yönetimi", active: true }, { name: "Personeller", active: true }] },
    { path: '/professionals', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: "Admin", active: true }, { name: "Kullanıcı Yönetimi", active: true }, { name: "Profesyoneller", active: true }] },
    { path: '/professional-detail', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: "Admin", active: true }, { name: "Kullanıcı Yönetimi", active: true }, { name: "Profesyonel Detay", active: true }] },
    { path: '/professional-edit', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: "Admin", active: true }, { name: "Kullanıcı Yönetimi", active: true }, { name: "Profesyonel Düzenle", active: true }] },
    { path: '/project-detail', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: "Admin", active: true }, { name: "Kullanıcı Yönetimi", active: true }, { name: "Profesyonel", active: true }, { name: "Proje", active: true }] },
    { path: '/idea-pano-detail', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: "Admin", active: true }, { name: "Kullanıcı Yönetimi", active: true }, { name: "Profesyonel", active: true }, { name: "Fikir Panosu", active: true }] },
    { path: '/products', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Marka Kataloğu', active: true }, { name: 'Ürün Yönetimi', active: true }, ] },
    { path: '/product-add', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Marka Kataloğu', active: true }, { name: 'Ürün Yönetimi', active: true }, { name: "Ürün Ekle", active: true }] },
    { path: '/productDetail', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Marka Kataloğu', active: true }, { name: 'Ürün Yönetimi', active: true }, { name: 'Ürün Detayı', active: true }] },
    { path: '/productEdit', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Marka Kataloğu', active: true }, { name: 'Ürün Yönetimi', active: true }, { name: 'Ürün Düzenleme', active: true }] },
    { path: '/productAudit', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Marka Kataloğu', active: true }, { name: 'Ürün Yönetimi', active: true }, { name: 'Ürün Denetimi', active: true }] },
    { path: '/brandManagement', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Marka Kataloğu', active: true }, { name: 'Marka - Bayi Yönetimi', active: true }, { name: 'Marka Yönetimi', active: true }] },
    { path: '/brandDetail', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Marka Kataloğu', active: true }, { name: 'Marka - Bayi Yönetimi', active: true }, { name: 'Marka Yönetimi', active: true, link:'/brandManagement' }, { name: 'Marka Detayı', active: true }] },
    { path: '/brandAdd', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Marka Kataloğu', active: true }, { name: 'Marka - Bayi Yönetimi', active: true }, { name: 'Marka Yönetimi', active: true, link:'/brandManagement' }, { name: 'Marka Ekleme', active: true }] },
    { path: '/brandEdit', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Marka Kataloğu', active: true }, { name: 'Marka - Bayi Yönetimi', active: true }, { name: 'Marka Yönetimi', active: true, link: '/brandManagement' }, { name: 'Marka Düzenleme', active: true }] },
    { path: '/dealerManagement', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Marka Kataloğu', active: true }, { name: 'Marka - Bayi Yönetimi', active: true }, { name: 'Bayi Yönetimi', active: true }] },
    { path: '/dealerAdd', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Marka Kataloğu', active: true }, { name: 'Marka - Bayi Yönetimi', active: true }, { name: 'Bayi Yönetimi', active: true, link:'/dealerManagement' }, { name: 'Bayi Ekleme', active: true }] },
    { path: '/dealerEdit', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Marka Kataloğu', active: true }, { name: 'Marka - Bayi Yönetimi', active: true },{ name: 'Bayi Yönetimi', active: true, link:'/dealerManagement' }, { name: 'Bayi Düzenleme', active: true }] },
    { path: '/blogManagement', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: "Admin", active: true }, { name: "Blog Yönetimi", active: true },  { name: "Alt Bloglar", active: true }] },
    { path: '/blogSliderManagement', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: "Admin", active: true }, { name: "Blog Yönetimi", active: true },  { name: "Slider", active: true }] },
    { path: '/feedbackManagement', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: "Görüş & Öneri Yönetimi", active: true }] },
    
    { path: '/homepage/homepage-edits', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Anasayfa', active: true }] },
    { path: '/admin/ads', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Admin', active: true }, { name: 'Reklam', active: true }] },
    { path: '/admin/sss-footer', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Admin', active: true }, { name: 'SSS - Footer', active: true }] },
    { path: '/explore/shortcut', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Keşfet', active: true }, { name: 'Shortcut', active: true }] },
    { path: '/prof-services/featured', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: 'Pro Hizmet Al', active: true }, { name: 'Öne Çıkan Hizmetler', active: true }] },
    { path: '/prof-services/service-category-management', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: "Pro Hizmet Al", active: true }, { name: "Hizmet Alanları Yönetimi", active: true }] },
    { path: '/prof-services/fast-search', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: "Pro Hizmet Al", active: true }, { name: "Hızlı Aramalar", active: true }] },
    { path: '/brand-catalog/fast-search', breadcrumb: [{ name: 'Giriş', active: true, link: '/' }, { name: "Marka Kataloğu", active: true }, { name: "Hızlı Aramalar", active: true }] },
    
    { path: '/dashboard', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: "Gösterge Paneli", active: true }] },
    { path: '/login-modal', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: "Login Modal", active: true }] },
    { path: '/buttons', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Buttons', active: true }] },
    { path: '/cards', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Cards', active: true }] },
    { path: '/widgets', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Widgets', active: true }] },
    { path: '/typography', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Typography', active: true }] },
    { path: '/alerts', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Alerts', active: true }] },
    { path: '/tables', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Tables', active: true }] },
    { path: '/badges', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Badges', active: true }] },
    { path: '/button-groups', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Button-groups', active: true }] },
    { path: '/dropdowns', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Dropdowns', active: true }] },
    { path: '/progress', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Progress', active: true }] },
    { path: '/modals', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Modals', active: true }] },
    { path: '/forms', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Forms', active: true }] },
    { path: '/input-groups', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Input-groups', active: true }] },
    { path: '/charts', breadcrumb: [{ name: 'Giriş', active: true, link: '/' },  { name: 'Charts', active: true }] }    
]