import sidebarBgImage from "assets/images/sidebar/sidebar-4.jpg";
import React from "react";
import { FaListAlt } from "react-icons/fa";
import { GiCheckboxTree } from "react-icons/gi";
import { ImTree, ImProfile } from "react-icons/im";
import { VscSymbolMethod } from "react-icons/vsc";
import { HiBadgeCheck } from "react-icons/hi";
import {
  MdSlideshow,
  MdDashboard,
  MdKeyboardArrowDown,
  MdVerifiedUser,
  MdPlaylistAdd,
  MdPersonPin,
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import {
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from "reactstrap";
import bn from "utils/bemnames";
import DM from "../../utils/DataManagement";

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};

const roles = {
  admin: "admin",
  editor: "editor",
};

const bem = bn.create("sidebar");

class Sidebar extends React.Component {
  state = {
    userRole: null,
    isOpenHomepage: false,
    isOpenAdmin: false,
    isOpenExplore: false,
    isOpenBrandCatalog: false,
    isOpenProServices: false,
    isOpenAds: false,
    isOpenUserManage: false,
    isOpenSssAndFooter: false,
    isOpenBlogManage: false,
    isOpenShortcut: false,
    isOpenBrandDealerManage: false,
    isOpenProductManage: false,
    isOpenBrandCategoryManage: false,
    isOpenProServiceCategoryManage: false,
    isOpenFeaturedServices: false,
  };

  topList = ["Homepage", "Admin", "Explore", "BrandCatalog", "ProServices"];

  subList = [
    "Ads",
    "UserManage",
    "SssAndFooter",
    "BlogManage",
    "Shortcut",
    "BrandDealerManage",
    "ProductManage",
    "BrandCategoryManage",
    "ProServiceCategoryManage",
    "FeaturedServices",
  ];

  componentWillMount() {
    const user = DM.getLoggedInUser();
    this.setState({ userRole: user.role });
  }

  handleClick = (listType, name) => () => {
    const list =
      listType === "top" ? this.topList.concat(this.subList) : this.subList;
    this.setState((prevState) => {
      const isOpen = prevState[`isOpen${name}`];
      const obj = { [`isOpen${name}`]: !isOpen };
      let objList = list
        .filter((i) => i !== name)
        .map((i) => {
          return { [`isOpen${i}`]: false };
        });
      objList.push(obj);
      return Object.assign({}, ...objList);
    });
  };

  textTransformToUppercase = (text) => text.toLocaleUpperCase("tr-TR");

  render() {
    const {
      userRole,
      isOpenHomepage,
      isOpenAdmin,
      isOpenExplore,
      isOpenBrandCatalog,
      isOpenProServices,
      isOpenAds,
      isOpenUserManage,
      isOpenSssAndFooter,
      isOpenBlogManage,
      isOpenShortcut,
      isOpenBrandDealerManage,
      isOpenProductManage,
      isOpenBrandCategoryManage,
      isOpenProServiceCategoryManage,
      isOpenFeaturedServices,
    } = this.state;
    // console.log("role:", userRole)
    const topNavItems = [
      {
        to: "/homepage/homepage-edits",
        name: "anasayfa",
        key: "Homepage",
        state: isOpenHomepage,
        exact: true,
        Icon: null,
        forRole: roles.editor,
      },
      {
        to: "/admin",
        name: "admin",
        key: "Admin",
        state: isOpenAdmin,
        exact: true,
        Icon: null,
        forRole: roles.editor,
      },
      {
        to: "/",
        name: "keşfet",
        key: "Explore",
        state: isOpenExplore,
        exact: true,
        Icon: null,
        forRole: roles.editor,
      },
      {
        to: "/blogManagement",
        name: "marka kataloğu",
        key: "BrandCatalog",
        state: isOpenBrandCatalog,
        exact: true,
        Icon: null,
        forRole: roles.editor,
      },
      // {
      //   to: "/dealerManagement",
      //   name: "pro hizmet al",
      //   key: "ProServices",
      //   state: isOpenProServices,
      //   exact: true,
      //   Icon: null,
      //   forRole: roles.editor,
      // },
    ];
    const subItems = {
      Admin: [
        {
          to: "/admin/ads",
          name: "reklam",
          key: "Ads",
          state: isOpenAds,
          exact: true,
          Icon: null,
          forRole: roles.editor,
        },
        {
          to: "/",
          name: "kullanıcı yönetimi",
          key: "UserManage",
          state: isOpenUserManage,
          exact: true,
          Icon: null,
          forRole: roles.admin,
        },
        {
          to: "/admin/sss-footer",
          name: "sss ve footer",
          key: "SssAndFooter",
          state: isOpenSssAndFooter,
          exact: true,
          Icon: null,
          forRole: roles.editor,
        },
        {
          to: "/",
          name: "blog yönetimi",
          key: "BlogManage",
          state: isOpenBlogManage,
          exact: true,
          Icon: null,
          forRole: roles.editor,
        },
      ],
      Explore: [
        {
          to: "/explore/shortcut",
          name: "shortcut",
          key: "Shortcut",
          state: isOpenShortcut,
          exact: true,
          Icon: null,
          forRole: roles.editor,
        },
      ],
      BrandCatalog: [
        {
          to: "/",
          name: "marka / bayi yönetimi",
          key: "BrandDealerManage",
          state: isOpenBrandDealerManage,
          exact: true,
          Icon: null,
          forRole: roles.editor,
        },
        {
          to: "/",
          name: "ürün yönetimi",
          key: "ProductManage",
          state: isOpenProductManage,
          exact: true,
          Icon: null,
          forRole: roles.editor,
        },
        {
          to: "/",
          name: "kategori yönetimi",
          key: "BrandCategoryManage",
          state: isOpenBrandCategoryManage,
          exact: true,
          Icon: null,
          forRole: roles.editor,
        },
      ],
      // ProServices: [
      //   {
      //     to: "/prof-services",
      //     name: "kategori yönetimi",
      //     key: "ProServiceCategoryManage",
      //     state: isOpenProServiceCategoryManage,
      //     exact: true,
      //     Icon: null,
      //     forRole: roles.editor,
      //   },
      //   {
      //     to: "/prof-services/featured",
      //     name: "öne çıkan hizmetler",
      //     key: "FeaturedServices",
      //     state: isOpenFeaturedServices,
      //     exact: true,
      //     Icon: null,
      //     forRole: roles.editor,
      //   },
      // ],
    };
    const subSubItems = {
      UserManage: [
        {
          to: "/personels",
          name: "Personeller",
          exact: false,
          Icon: MdVerifiedUser,
          forRole: roles.admin,
        },
        {
          to: "/users",
          name: "Kullanıcılar",
          exact: false,
          Icon: MdPersonPin,
          forRole: roles.admin,
        },
        {
          to: "/professionals",
          name: "Profesyoneller",
          exact: false,
          Icon: ImProfile,
          forRole: roles.admin,
        },
      ],
      BlogManage: [
        {
          to: "/blogSliderManagement",
          name: "Slider",
          exact: true,
          Icon: MdSlideshow,
          forRole: roles.editor,
        },
        {
          to: "/blogManagement",
          name: "Alt Bloglar",
          exact: true,
          Icon: MdDashboard,
          forRole: roles.editor,
        },
      ],
      BrandDealerManage: [
        {
          to: "/brandManagement",
          name: "Marka Yönet",
          exact: false,
          Icon: VscSymbolMethod,
          forRole: roles.editor,
        },
        {
          to: "/dealerManagement",
          name: "Bayi Yönet",
          exact: false,
          Icon: GiCheckboxTree,
          forRole: roles.editor,
        },
      ],
      ProductManage: [
        {
          to: "/products",
          name: "Tüm Ürünler",
          exact: false,
          Icon: FaListAlt,
          forRole: roles.editor,
        },
        {
          to: "/product-add",
          name: "Ürün Ekle",
          exact: false,
          Icon: MdPlaylistAdd,
          forRole: roles.editor,
        },
        {
          to: "/productAudit",
          name: "Ürün Denetimi",
          exact: false,
          Icon: HiBadgeCheck,
          forRole: roles.editor,
        },
      ],
      BrandCategoryManage: [
        {
          to: "/categoryManagement",
          name: "Kategoriler",
          exact: false,
          Icon: ImTree,
          forRole: roles.admin,
        },
        {
          to: "/categoryGalleries",
          name: "Mobil Hızlı Arama",
          exact: false,
          Icon: ImTree,
          forRole: roles.editor,
        },
        {
          to: "/brand-catalog/fast-search",
          name: "Web Hızlı Arama",
          exact: false,
          Icon: ImTree,
          forRole: roles.editor,
        },
      ],
      // ProServiceCategoryManage: [
      //   {
      //     to: "/prof-services/service-category-management",
      //     name: "Kategoriler",
      //     exact: false,
      //     Icon: ImTree,
      //     forRole: roles.admin,
      //   },
      //   {
      //     to: "/prof-services/fast-search",
      //     name: "Hızlı Arama",
      //     exact: false,
      //     Icon: ImTree,
      //     forRole: roles.editor,
      //   },
      // ],
    };

    const childrenCheck = (array, key) => {
      if (array[key] && array[key].length > 0) return true;
      return false;
    };

    return (
      <aside className={bem.b()}>
        <div className={bem.e("background")} style={sidebarBackground} />
        <div className={bem.e("content")}>
          <Navbar>
            <NavLink to="/">
              <img
                src="https://materyum-test.s3.eu-north-1.amazonaws.com/admin/materyum-text-logo-white.png"
                style={{ width: "100%", marginTop: 10 }}
                className="pr-2"
                alt=""
              />
              <span style={{ fontSize: 16, color: "white", paddingLeft: 3.5 }}>
                Yönetim Paneli
              </span>
            </NavLink>
          </Navbar>
          <Nav vertical>
            {topNavItems
              .filter((i) => {
                if (userRole === roles.admin) {
                  return i;
                } else {
                  if (i.forRole === roles.editor) {
                    return i;
                  }
                }
              })
              .map(({ to, name, key, state, exact, Icon }, index) => (
                <React.Fragment key={key}>
                  <NavItem
                    className={bem.e("nav-item")}
                    onClick={this.handleClick("top", key)}
                  >
                    <BSNavLink
                      className={bem.e("nav-item-collapse")}
                      tag={!childrenCheck(subItems, key) ? NavLink : undefined}
                      to={to}
                      // activeClassName="active"
                      exact={exact}
                    >
                      <div className="d-flex">
                        {/* {Icon && <Icon className={bem.e("nav-item-icon")} />} */}
                        <span
                          className=" align-self-start"
                          style={{ fontSize: 18, fontWeight: "500" }}
                        >
                          {this.textTransformToUppercase(name)}
                        </span>
                      </div>
                      {childrenCheck(subItems, key) && (
                        <MdKeyboardArrowDown
                          className={bem.e("nav-item-icon")}
                          style={{
                            padding: 0,
                            transform: state
                              ? "rotate(0deg)"
                              : "rotate(-90deg)",
                            transitionDuration: "0.3s",
                            transitionProperty: "transform",
                          }}
                        />
                      )}
                    </BSNavLink>
                  </NavItem>

                  {childrenCheck(subItems, key) && (
                    <Collapse isOpen={state}>
                      {subItems[key]
                        .filter((i) => {
                          if (userRole === roles.admin) {
                            return i;
                          } else {
                            if (i.forRole === roles.editor) {
                              return i;
                            }
                          }
                        })
                        .map(({ to, name, key, state, exact, Icon }, index) => (
                          <React.Fragment key={index}>
                            <NavItem
                              className={bem.e("nav-item")}
                              onClick={this.handleClick("sub", key)}
                            >
                              <BSNavLink
                                className={bem.e("nav-item-collapse")}
                                tag={
                                  !childrenCheck(subSubItems, key)
                                    ? NavLink
                                    : undefined
                                }
                                to={to}
                                // activeClassName="active"
                                exact={exact}
                                style={{ paddingLeft: "18%" }}
                              >
                                <div className="d-flex">
                                  {/* {Icon && <Icon className={bem.e("nav-item-icon")} />} */}
                                  <span
                                    className="align-self-start"
                                    style={{ fontSize: 15 }}
                                  >
                                    {this.textTransformToUppercase(name)}
                                  </span>
                                </div>
                                {childrenCheck(subSubItems, key) && (
                                  <MdKeyboardArrowDown
                                    className={bem.e("nav-item-icon")}
                                    style={{
                                      padding: 0,
                                      transform: state
                                        ? "rotate(0deg)"
                                        : "rotate(-90deg)",
                                      transitionDuration: "0.3s",
                                      transitionProperty: "transform",
                                    }}
                                  />
                                )}
                              </BSNavLink>
                            </NavItem>

                            {childrenCheck(subSubItems, key) && (
                              <Collapse isOpen={state}>
                                {subSubItems[key]
                                  .filter((i) => {
                                    if (userRole === roles.admin) {
                                      return i;
                                    } else {
                                      if (i.forRole === roles.editor) {
                                        return i;
                                      }
                                    }
                                  })
                                  .map(({ to, name, exact, Icon }, index) => (
                                    <NavItem
                                      key={index}
                                      className={bem.e("nav-item")}
                                    >
                                      <BSNavLink
                                        id={`navItem-${name}-${index}`}
                                        className="text"
                                        tag={NavLink}
                                        to={to}
                                        // activeClassName="active"
                                        exact={exact}
                                        style={{ paddingLeft: "36%" }}
                                      >
                                        {/* {Icon && <Icon className={bem.e("nav-item-icon")} /> } */}
                                        <span
                                          className="align-self-start"
                                          style={{ fontSize: 15 }}
                                        >
                                          {name}
                                        </span>
                                      </BSNavLink>
                                    </NavItem>
                                  ))}
                              </Collapse>
                            )}
                          </React.Fragment>
                        ))}
                    </Collapse>
                  )}
                </React.Fragment>
              ))}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
