export const localStrings = {
  sortableType: {
    standart: 'standart',
    website: 'website',
  },
  fastSearchType: {
    service: 'service',
    product: 'product',
  },
};
