import PropTypes from "prop-types";
import React from "react";
import { Redirect } from "react-router-dom";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { config } from "../configs";
import { message } from "antd";
import DM from "../utils/DataManagement";

const configURL = config.server_url;

class AuthForm extends React.Component {
  state = {
    redirect: null,
  };

  handleInputChange = (event) => {
    if (event.target.name === "email")
      this.setState({ email: event.target.value });

    if (event.target.name === "password")
      this.setState({ password: event.target.value });

    event.preventDefault();
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    let url = configURL + "/admin/login";

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
      }),
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ postId: data.id });
        // console.log(data);

        if (data.success == true) {
          message.success(data.message);
          localStorage.setItem("access_token", data.access_token); // https://medium.com/@damlaakdemr/local-storage-ve-session-storage-nedir-nas%C4%B1l-kullan%C4%B1l%C4%B1r-237aa647b412
          localStorage.setItem("username", data.user.username);
          localStorage.setItem("name", data.user.name);
          localStorage.setItem("role", data.user.role);
          DM.setLoggedInUser({
            username: data.user.username,
            name: data.user.name,
            role: data.user.role,
            access_token: data.access_token,
          });
          setTimeout(
            () => message.loading("Anasayfaya yönlendiriliyorsunuz..."),
            1000
          );
          setTimeout(() => {
            this.setState({ redirect: "/" });
          }, 3000);
        } else message.error(data.message);
      });
  };

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      children,
    } = this.props;

    if (this.state.redirect) {
      return <Redirect push to={{ pathname: this.state.redirect }} />;
    }

    onkeydown = (e) => {
      if (window.location.pathname == "/login" && e.key == "Enter") {
        this.handleSubmit();
      }
    };

    return (
      <Form>
        {showLogo && (
          <div className="text-center pb-4">
            <img
              src="https://materia-logo.s3.eu-central-1.amazonaws.com/text-logo-B7E8EF.png"
              className="rounded"
              style={{ width: "50%", marginTop: "10px" }}
              alt="logo"
            />
          </div>
        )}

        <FormGroup>
          <Label for={usernameLabel}>E-posta</Label>
          <Input
            {...usernameInputProps}
            name="email"
            onChange={this.handleInputChange}
          />
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel}>Şifre</Label>
          <Input
            {...passwordInputProps}
            name="password"
            onChange={this.handleInputChange}
          />
        </FormGroup>

        <hr />

        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          onClick={this.handleSubmit}
        >
          Giriş yap
        </Button>
        {children}
      </Form>
    );
  }
}

AuthForm.propTypes = {
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
};

AuthForm.defaultProps = {
  showLogo: true,
  usernameLabel: "Email",
  usernameInputProps: {
    type: "email",
    placeholder: "",
  },
  passwordLabel: "Password",
  passwordInputProps: {
    type: "password",
    placeholder: "",
  },
};

export default AuthForm;
