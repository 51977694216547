const config = {

  server_url: process.env.REACT_APP_CONFIG_URL || "https://api.materiasoft.com",
  roles: [
    { id: 1, key: "admin", name: "Admin" },
    { id: 2, key: "editor", name: "Editör" },
    { id: 3, key: "user", name: "Kullanıcı" },
  ],
  genders: [
    { id: 0, key: "null", name: "Belirtmek istemiyorum" },
    { id: 1, key: "male", name: "Erkek" },
    { id: 2, key: "female", name: "Kadın" },
  ],
  ages: [
    { id: 0, key: "null", name: "Belirtmek istemiyorum" },
    { id: 1, key: "1", name: "0-23" },
    { id: 2, key: "2", name: "24-30" },
    { id: 4, key: "3", name: "31-40" },
    { id: 5, key: "4", name: "41-55" },
    { id: 6, key: "5", name: "55+" },
  ],
  productAuditFields: {
    defaultFields: [
      // { key: 0, value: "images", name: "Fotoğraf" },
      // { key: 1, value: "category_id", name: "Kategori Id" },
      // { key: 2, value: "brand_id", name: "Marka" },
      { key: 3, value: "collection_name", name: "Koleksiyon" },
      { key: 4, value: "model", name: "Model" },
      { key: 5, value: "color", name: "Renk" },
      { key: 6, value: "width", name: "En" },
      { key: 7, value: "length", name: "Boy" },
      { key: 8, value: "contents", name: "Ürün içeriği" },
      // { key: 9, value: "exist_at_home", name: "Ana sayfada çıksın" },
      // { key: 10, value: "exist_at_category", name: "Kategori sayfasında çıksın" },
      { key: 11, value: "web_site", name: "Website" },
      { key: 12, value: "designer", name: "Tasarımcı" },
      { key: 13, value: "notes", name: "Notlar" },
      { key: 14, value: "usage_area", name: "Kullanım alanı" },
      { key: 15, value: "thickness", name: "Kalınlık" },
      { key: 16, value: "material", name: "Malzeme" },
      { key: 17, value: "height", name: "Yükseklik" },
    ],
  },
  alphabet: [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ],
};

export { config };
