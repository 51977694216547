import React from 'react';
import { Link, Redirect } from 'react-router-dom'
import { Dropdown, Button, Card, Avatar, message } from 'antd'
import { MdClearAll, MdExitToApp } from 'react-icons/md';
import { Breadcrumb, BreadcrumbItem, ListGroup, ListGroupItem, Nav, Navbar, NavItem, NavLink } from 'reactstrap';
import { allPaths } from '../../utils/breadcrumbs'
import { config } from '../../configs';
import bn from 'utils/bemnames';
import adminAvatar from '../../assets/images/avatar/admin.png';
import editorAvatar from '../../assets/images/avatar/editor.png';
import DM from '../../utils/DataManagement';

const { Meta } = Card;
const bem = bn.create('header');
const configURL = config.server_url;

class Header extends React.Component {
  state = {
    isNotificationConfirmed: false,
    loggedInUser: undefined
  };

  componentWillMount() {
    this.loadToLoggedInUser()
  }

  loadToLoggedInUser = () => {
    let user = DM.getLoggedInUser();
    user.avatar = user.role === 'admin' ? adminAvatar : editorAvatar;
    user.role = this.capitalizeFirstLetter(user.role)
    this.setState({ loggedInUser: user })
  }

  capitalizeFirstLetter = (string) => {                         // make the first letter of a string uppercase
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    } else {
      return ""
    }
  }

  logout = async () => {
    let url = configURL + "/users/logout"
    const requestOptions = {
      method: "GET",
      headers: {
        "Authorization": "Bearer: " + localStorage.getItem("access_token"),
      },
      credentials: "same-origin",
      withCredentials: true,
    };
    fetch(url, requestOptions)
      .then(response => response.json())
      .then(data => {
        message.info("Çıkış yapıldı!");
      })
      .then(
        localStorage.clear()
      )
  }

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    const { pathname } = this.props
    const crumb = allPaths.filter(i => i.path == pathname)[0].breadcrumb
    const { loggedInUser } = this.state
    const avatar = loggedInUser && loggedInUser.avatar
    const menu = (
      <ListGroup >
        <ListGroupItem action className="border-light">
          <Card
            style={{ width: 300, borderRadius: 5, color: 'black' }}>
            <Meta
              avatar={<Avatar src={avatar} />}
              title={loggedInUser ? loggedInUser.name : ""}
              description={loggedInUser ? loggedInUser.role : ""}
            />

            <Link to="/login" onClick={() => this.logout()}>
              <Button type="primary" danger block style={{ alignItems: 'center', justifyContent: 'center', marginTop: 15 }}>
                <MdExitToApp size={20} style={{ marginRight: 5, color: 'black' }} />
                Çıkış
                </Button>
            </Link>

          </Card>

        </ListGroupItem>
      </ListGroup>

    );

    return (
        <Navbar light expand className={bem.b('bg-white')} style={{ marginTop: 5 }}>

          <Nav navbar className="mr-2">
            <Button onClick={this.handleSidebarControlButton} >
              <MdClearAll size={25} />
            </Button>
          </Nav>

          <Nav style={{ marginLeft: 5, marginTop: 16.5 }}>
            <Breadcrumb className={bem.e("breadcrumb")}>
              {crumb.length &&
                crumb.map(({ name, active, link }, index) => (
                  <BreadcrumbItem key={index} active={active}>
                    {link ? <Link to={link}>{name}</Link> : <span>{name}</span>}
                  </BreadcrumbItem>
                ))}
            </Breadcrumb>
          </Nav>

          <Nav navbar className={bem.e('nav-right')}>
            <NavItem>
              <Dropdown overlay={menu} placement="bottomRight" arrow trigger={['click']} >
                <NavLink>
                  <Avatar
                    src={avatar}
                  />
                </NavLink>
              </Dropdown>
            </NavItem>
          </Nav>

        </Navbar>
    );
  }
}

export default Header;

/*

import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import SearchInput from 'components/SearchInput';
import { notificationsData } from 'demos/header';
import withBadge from 'hocs/withBadge';
import Typography from "../Typography";
import DM from '../../utils/DataManagement'
import { EditOutlined, EllipsisOutlined, SettingOutlined } from '@ant-design/icons';
import { MdClearAll, MdExitToApp, MdHelp, MdInsertChart, MdMessage, MdNotificationsActive, MdNotificationsNone, MdPersonPin, MdSettingsApplications, } from 'react-icons/md';

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  onClick={this.toggleUserCardPopover}

    state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    loggedInUser: undefined
    };

  <Nav style={{ marginTop: 6 }}>
    <Typography type="h2" className={bem.e("title")}>
      Ürünler
    </Typography>
  </Nav>



  <Nav navbar>
    <SearchInput />
  </Nav>

  <BreadcrumbItem><Link to="/">Anasayfa</Link></BreadcrumbItem>



            <NavItem className="d-inline-flex">
            <NavLink id="Popover1" className="position-relative">
              {isNotificationConfirmed ? (
                <MdNotificationsNone
                  size={25}
                  className="text-secondary can-click"
                  onClick={this.toggleNotificationPopover}
                />
              ) : (
                  <MdNotificationsActiveWithBadge
                    size={25}
                    className="text-secondary can-click animated swing infinite"
                    onClick={this.toggleNotificationPopover}
                  />
                )}
            </NavLink>
            <Popover
              placement="bottom"
              isOpen={this.state.isOpenNotificationPopover}
              toggle={this.toggleNotificationPopover}
              target="Popover1"
            >
              <PopoverBody>
                <Notifications notificationsData={notificationsData} />
              </PopoverBody>
            </Popover>
          </NavItem>


                              <ListGroupItem tag="button" action className="border-light">
                      <MdPersonPin /> Profile
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdInsertChart /> Stats
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdMessage /> Messages
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdSettingsApplications /> Settings
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdHelp /> Help
                    </ListGroupItem>


                    <NavItem>
            <NavLink id="Popover2">
              <Avatar
                onClick={this.toggleUserCardPopover}
                className="can-click"
                img={loggedInUser ? loggedInUser.profile_image[0].thumbUrl : null}
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title={loggedInUser ? loggedInUser.name : null}
                  subtitle={loggedInUser ? loggedInUser.email : null}
                  text={loggedInUser ? loggedInUser.role : null}
                  img={loggedInUser ? loggedInUser.profile_image[0].thumbUrl : null}
                  className="border-dark"
                >
                  <ListGroup flush>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdExitToApp /> Çıkış yap
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>


          */